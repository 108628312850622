<template>
  <div>
    <ca-header
      heading="Kaufschutz Tarife"
      :column-layout="true"
    >
      <template #buttonSpace>
        <router-link
          class="btn btn-primary"
          :to="{name: 'KaufschutzRateCreate' }"
        >
          Neuen Kaufschutz-Tarif hinzufügen
        </router-link>
      </template>
    </ca-header>

    <div class="container-fluid px-4 pt-4">
      <data-table
        :fields="fields"
        service="kaufschutz-rates"
        :base-query="baseQuery"
        title="Kaufschutz Tarife"
        :search-field="false"
        @row-clicked="(item) => $router.push({name: 'KaufschutzRateEdit', params: {kaufschutzRateId: item._id}})"
      />
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DataTable from '@/components/DataTable/DataTable.vue'
import formatPrice from '../../filters/formatPrice'
function parseCarCondition (condition) {
  switch (condition) {
  case 'NEW': return 'Neuwagen'
  case 'USED': return 'Gebrauchtwagen'
  }
}
export default {
  name: 'KaufschutzRateList',
  components: {
    CaHeader,
    DataTable
  },
  data: () => ({
    baseQuery: {
      $sort: { carCondition: 1, laufzeit: 1, carPriceFrom: 1 }
    },
    fields: [
      {
        key: 'product.hn',
        label: 'HN'
      },
      {
        key: 'product.name',
        label: 'UN'
      },
      {
        key: 'carPriceFrom',
        label: 'Preis von',
        formatter: formatPrice
      },
      {
        key: 'carPriceTo',
        label: 'Preis bis',
        formatter: formatPrice
      },
      {
        key: 'carCondition',
        label: 'Fahrzeugart',
        formatter: parseCarCondition
      },
      {
        key: 'laufzeit',
        label: 'Laufzeit',
        formatter: (laufzeit) => `${laufzeit} Monate`
      },
      {
        key: 'brutto',
        label: 'Brutto',
        formatter: formatPrice
      }
    ]
  })
}
</script>

<style scoped lang="scss">

</style>
